import React, { FunctionComponent, ReactElement } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import PlusIcon from '@wh-components/icons/Plus'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'

interface AzaWidgetProps {
    heading: string
    text: string
    azaUrl: string
    taggingAction: TaggingActionEvent
    taggingData: TaggingData
    image?: ReactElement
}

export const AzaWidget: FunctionComponent<AzaWidgetProps> = ({ heading, text, azaUrl, image = undefined, taggingAction, taggingData }) => (
    <Box display="flex" borderRadius="m" backgroundColor="palette.babyseal" border="owl">
        {image}
        <Box
            display="flex"
            padding="m"
            gap={{ phone: 's', tablet: 'm' }}
            flexDirection={{ phone: 'column', tablet: 'row' }}
            alignItems={{ tablet: 'center' }}
            justifyContent={{ tablet: 'space-between' }}
        >
            <Box>
                <Text as="p" fontWeight="bold">
                    {heading}
                </Text>
                <Text>{text}</Text>
            </Box>
            <ServerRoutingAnchorLink
                width={{ phone: '100%', tablet: 'auto' }}
                type="button"
                color="secondary"
                href={azaUrl}
                testId="vertical-home-new-ad-link"
                onClick={() => callActionEvent(taggingAction, taggingData)}
            >
                <PlusIcon size="small" color="semantic.foreground.white" marginRight="xs" />
                Anzeige aufgeben
            </ServerRoutingAnchorLink>
        </Box>
    </Box>
)
