import React, { FunctionComponent, ReactNode, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { css, useTheme } from 'styled-components'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { VerticalId, verticalIdMap } from '@wh/common/chapter/types/verticals'
import { useVerticalsInfo } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { SEOHeading } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/SearchEntryArea/SEOHeading'
import { SearchBoxRowContainer } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/SearchEntryArea/SearchBoxRowContainer'
import { SearchEntryAreaBottomIFrameContainer } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/SearchEntryArea/SearchEntryAreaBottomIFrameContainer'
import { ExtractedAdvertisingToPageMessage } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageProtocols'
import { SearchEntryAreaTabs } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/SearchEntryArea/SearchEntryAreaTabs'
import { ResponsiveValue, SystemValue } from '@wh-components/system'
import {
    AdvertisingPageModificationsState,
    SearchEntryAreaBottomIFrameType,
    SimpleIFrameType,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingPageModificationsState'

interface SearchEntryAreaProps {
    searchBox: ReactNode
    title?: string
    tabs?: ReactNode
    verticalId?: VerticalId
    tabsHeight?: ResponsiveValue<SystemValue>
    backgroundSmall: string
    backgroundMediumLarge: string
    backgroundText: string
    searchEntryAreaBottomIFrameKey?: keyof AdvertisingPageModificationsState
    searchBoxRightIFrameKey?: keyof AdvertisingPageModificationsState
    smallLeaderboardIFrameKey?: keyof AdvertisingPageModificationsState
    titleColorKey?: ExtractedAdvertisingToPageMessage<{ type: 'set-foreground-color' }>['target']
    titleCounterColorKey?: ExtractedAdvertisingToPageMessage<{ type: 'set-foreground-color' }>['target']
    searchEntryAreaBackgroundTransparentKey?:
        | 'motor-search-entry-area'
        | 'motor-search-box-parent-container'
        | 'estate-search-entry-area'
        | 'estate-search-box-parent-container'
    searchEntryAreaBackgroundColorKey?: ExtractedAdvertisingToPageMessage<{ type: 'set-background-color' }>['target']
    searchBoxParentContainerBackgroundTransparentKey?:
        | 'motor-search-entry-area'
        | 'motor-search-box-parent-container'
        | 'estate-search-entry-area'
        | 'estate-search-box-parent-container'
    searchBoxParentContainerBackgroundColorKey?: ExtractedAdvertisingToPageMessage<{
        type: 'set-background-color'
    }>['target']
}

export const SearchEntryArea: FunctionComponent<SearchEntryAreaProps> = ({
    searchBox,
    title = 'Die große Fahrzeugbörse Österreichs',
    tabs,
    verticalId = verticalIdMap.MOTOR,
    tabsHeight,
    backgroundSmall,
    backgroundMediumLarge,
    backgroundText,
    searchEntryAreaBottomIFrameKey = 'motorSearchEntryAreaBottomIFrame',
    searchBoxRightIFrameKey = 'motorSearchBoxRightIFrame',
    smallLeaderboardIFrameKey = 'motorSmallLeaderboardIFrame',
    titleColorKey = 'motor-title',
    titleCounterColorKey = 'motor-title-counter',
    searchEntryAreaBackgroundTransparentKey = 'motor-search-entry-area',
    searchEntryAreaBackgroundColorKey = 'motor-search-entry-area',
    searchBoxParentContainerBackgroundTransparentKey = 'motor-search-box-parent-container',
    searchBoxParentContainerBackgroundColorKey = 'motor-search-box-parent-container',
}) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const theme = useTheme()
    const [verticalsInfo] = useVerticalsInfo()
    const nrOfAdverts = verticalsInfo?.find((vertical) => vertical.id === verticalId)?.nrOfAdverts
    const titleColor = advertisingState.pageModifications.foregroundColors?.[titleColorKey]
    const titleCounterColor = advertisingState.pageModifications.foregroundColors?.[titleCounterColorKey]
    const searchEntryAreaBackgroundTransparent =
        advertisingState.pageModifications.backgroundTransparent?.includes(searchEntryAreaBackgroundTransparentKey) ?? false
    const searchEntryAreaBackgroundColor = searchEntryAreaBackgroundTransparent
        ? 'transparent'
        : (advertisingState.pageModifications.backgroundColors?.[searchEntryAreaBackgroundColorKey] ??
          theme.colors.semantic.surface.DEFAULT)
    const searchBoxParentContainerBackgroundTransparent =
        advertisingState.pageModifications.backgroundTransparent?.includes(searchBoxParentContainerBackgroundTransparentKey) ?? false
    const searchBoxParentContainerBackgroundColor =
        advertisingState.pageModifications.backgroundColors?.[searchBoxParentContainerBackgroundColorKey]
    const couldSearchBoxParentContainerBackgroundBeTransparent =
        searchBoxParentContainerBackgroundTransparent || typeof searchBoxParentContainerBackgroundColor !== 'undefined'

    // cannot show rounded border when the search-box-parent-container could be transparent, because the "hole" in the css is implemented by just coloring all the areas around it, which makes problems with areas left by rounded borders
    const canShowSearchBoxParentContainerBottomRoundedBorder = !couldSearchBoxParentContainerBackgroundBeTransparent
    const searchEntryAreaBottomIFrame = advertisingState.pageModifications[
        searchEntryAreaBottomIFrameKey
    ] as SearchEntryAreaBottomIFrameType
    const searchBoxRightIFrame = advertisingState.pageModifications[searchBoxRightIFrameKey] as SimpleIFrameType
    const shouldRenderSearchBoxRightIFrameBasedOnBreakpoint = useResponsiveValue({ phone: false, tablet: true }, false)
    const smallLeaderboardIFrame = advertisingState.pageModifications[smallLeaderboardIFrameKey] as SimpleIFrameType
    const shouldRenderSmallLeaderboardIFrameBasedOnBreakpoint = useResponsiveValue(
        {
            phone: true,
            tablet: false,
        },
        false,
    )

    return (
        // make the background color on phone always white, we do not want the site mutations to being able to make the page background visible
        <Box
            padding={{ phone: '0', tablet: 'm' }}
            // needs to be same spread as padding
            boxShadow={{ phone: undefined, tablet: `inset 0 0 0 ${theme.space.m}px ${searchEntryAreaBackgroundColor}` }}
            backgroundColor={{ phone: 'semantic.surface', tablet: 'transparent' }}
        >
            <Box
                display={{ phone: 'block', tablet: 'none' }}
                height={!smallLeaderboardIFrame ? 80 : 125}
                background={{
                    phone: !smallLeaderboardIFrame ? `${theme.colors.palette.primary.main} url("${backgroundSmall}") no-repeat` : undefined,
                    tablet: undefined,
                }}
            >
                {shouldRenderSmallLeaderboardIFrameBasedOnBreakpoint && !!smallLeaderboardIFrame && (
                    <iframe
                        src={smallLeaderboardIFrame.src}
                        frameBorder="0"
                        title="Werbung"
                        aria-hidden="true"
                        css={css`
                            display: block;
                            width: 100%;
                            height: 100%;

                            ${(p) => p.theme.media.print} {
                                display: none;
                            }
                        `}
                    />
                )}
            </Box>
            <Box paddingBottom="s" display={{ phone: 'none', tablet: 'block' }} backgroundColor={searchEntryAreaBackgroundColor}>
                <SEOHeading title={title} nrOfAdverts={nrOfAdverts} titleColor={titleColor} titleCounterColor={titleCounterColor} />
            </Box>
            <Box width="100%">
                {tabs && (
                    <Box width="100%" backgroundColor={searchEntryAreaBackgroundColor}>
                        <SearchEntryAreaTabs tabs={tabs} height={tabsHeight} />
                    </Box>
                )}

                <Box
                    backgroundColor={couldSearchBoxParentContainerBackgroundBeTransparent ? 'transparent' : searchEntryAreaBackgroundColor}
                >
                    <Box
                        width="100%"
                        padding={{ phone: '0', tablet: 'm' }}
                        borderRadius={{
                            phone: undefined,
                            tablet: canShowSearchBoxParentContainerBottomRoundedBorder
                                ? `0 0 ${theme.borderRadius}px ${theme.borderRadius}px`
                                : '0 0 0 0',
                        }}
                        background={{
                            phone: undefined,
                            tablet: searchBoxParentContainerBackgroundTransparent
                                ? 'transparent'
                                : typeof searchBoxParentContainerBackgroundColor === 'undefined'
                                  ? `${theme.colors.palette.primary.main} url("${backgroundMediumLarge}") no-repeat`
                                  : searchBoxParentContainerBackgroundColor,
                            desktop: searchBoxParentContainerBackgroundTransparent
                                ? 'transparent'
                                : typeof searchBoxParentContainerBackgroundColor === 'undefined'
                                  ? `url("${backgroundText}") calc(100% - 32px) calc(100% - 32px) no-repeat, url("${backgroundMediumLarge}") 0 no-repeat, ${theme.colors.palette.primary.main}`
                                  : searchBoxParentContainerBackgroundColor,
                        }}
                        backgroundSize={{ phone: undefined, tablet: 'cover', desktop: 'auto, cover' }}
                    >
                        <SearchBoxRowContainer
                            left={searchBox}
                            right={
                                shouldRenderSearchBoxRightIFrameBasedOnBreakpoint &&
                                typeof searchBoxRightIFrame !== 'undefined' && (
                                    <iframe
                                        src={searchBoxRightIFrame.src}
                                        frameBorder="0"
                                        title="Werbung"
                                        aria-hidden="true"
                                        css={css`
                                            display: block;
                                            width: 100%;
                                            height: 100%;

                                            ${(p) => p.theme.media.print} {
                                                display: none;
                                            }
                                        `}
                                    />
                                )
                            }
                        />
                    </Box>
                </Box>
            </Box>
            {typeof searchEntryAreaBottomIFrame !== 'undefined' && (
                <Box
                    paddingTop="m"
                    paddingHorizontal="m" // needs to be the same as the search box container
                    paddingBottom={{ phone: 'm', tablet: '0' }}
                    backgroundColor={searchEntryAreaBackgroundColor}
                    marginBottom="xs"
                >
                    <SearchEntryAreaBottomIFrameContainer config={searchEntryAreaBottomIFrame} />
                </Box>
            )}
        </Box>
    )
}
