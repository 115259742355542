import React, { FunctionComponent, PropsWithChildren, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { css, useTheme } from 'styled-components'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { Button } from '@wh-components/core/Button/Button'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { detailSearchUrl } from '@bbx/search-journey/sub-domains/search/lib/detailSearchUrl'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { getNavigatorById, getRangeNavigatorById } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { RangeNavigatorComponent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/RangeNavigatorComponent/RangeNavigatorComponent'
import { CarTypeNavigator } from './CarTypeNavigator'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { MotorConditionCheckboxNavigator } from './MotorConditionCheckboxNavigator'
import { Navigator } from '@bbx/search-journey/common/Navigators'
import { MakeModalNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/MakeModalNavigator'
import { ModalNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/ModalNavigator'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { KeywordNavigator } from '../../../../common/common/Navigators/KeywordNavigator/KeywordNavigator'
import { useStartPageSearch } from '../../../../common/start-pages/useStartPageSearch'
import { SearchHistoryComponent, useLastSearches } from '@bbx/search-journey/common/components/SearchHistory/SearchHistory'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { NextDynamicRoute } from '@bbx/common/routing/nextDynamicRoutes'
import { StickySearchButtonMobile } from '../../../../common/start-pages/StickySearchButtonMobile'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'

export interface SearchBoxProps {
    type: 'car' | 'motorcycle'
    initialSearchResult: SearchResult
}

export const SearchBox: FunctionComponent<SearchBoxProps> = ({ type, initialSearchResult }) => {
    const { searchHistory } = useLastSearches(verticalIdMap.MOTOR)
    const advertisingState = useContext(AdvertisingStateContext)
    const theme = useTheme()
    const searchBoxBackgroundColor = advertisingState.pageModifications.backgroundColors?.['motor-search-box']
    const searchBoxBorderColor = advertisingState.pageModifications.borders?.['motor-search-box']
    const searchBoxForegroundTextColor = advertisingState.pageModifications.foregroundColors?.['motor-search-box-text']
    const searchBoxForegroundLinkColor = advertisingState.pageModifications.foregroundColors?.['motor-search-box-links']
    const searchBoxSearchButtonOutlineActivated = advertisingState.pageModifications.borders?.['motor-search-box-search-button'] ?? false

    const { currentSearchResult, resetSearch, triggerSearch, isSearchInProgress, abortRequest } = useStartPageSearch(
        initialSearchResult,
        NextDynamicRoute.AUTO_MOTOR_RESULT_LIST,
    )

    // we require all navigators to be present except modelNavigator
    const makeNavigator = getNavigatorById(currentSearchResult, 'make')
    const modelNavigator: Navigator | undefined = getNavigatorById(currentSearchResult, 'model')
    const keywordNavigator = getNavigatorById(currentSearchResult, 'keyword')
    const typeNavigator = getNavigatorById(currentSearchResult, 'type')
    const mileageNavigator = getRangeNavigatorById(currentSearchResult, 'mileage')!
    const yearmodelNavigator = getRangeNavigatorById(currentSearchResult, 'yearmodel')!
    const priceNavigator = getRangeNavigatorById(currentSearchResult, 'price')!
    const motorconditionNavigator = getNavigatorById(currentSearchResult, 'MOTORCONDITION')

    return (
        <Box
            background={
                searchBoxBackgroundColor ?? { phone: theme.colors.palette.polarbear, tablet: theme.colors.semantic.surface.DEFAULT }
            }
            padding={{ tablet: 'm' }}
            paddingTop="m"
            paddingBottom="s"
            border={{ phone: undefined, tablet: `1px solid ${searchBoxBorderColor ?? theme.colors.palette.owl}` }}
            borderRadius={{ phone: undefined, tablet: `${theme.borderRadius}px` }}
        >
            <Box paddingHorizontal={{ phone: 'm', tablet: '0' }}>
                <Box marginBottom={{ phone: 's', tablet: 'sm' }}>
                    <Text
                        fontSize={{ phone: 'm', tablet: 's' }}
                        fontWeight="bold"
                        color={searchBoxForegroundTextColor ?? 'palette.verydarkgrey'}
                    >
                        {headingForType(type)}
                    </Text>
                </Box>
                {type === 'car' && <CarTypeNavigator navigator={typeNavigator} onSearch={triggerSearch} />}
                {type === 'motorcycle' && (
                    <Box display={{ phone: 'none', tablet: 'block' }} marginBottom="m" maxWidth={290}>
                        <ModalNavigator
                            navigator={typeNavigator}
                            disabled={isSearchInProgress}
                            onSearch={triggerSearch}
                            label="Fahrzeugtyp"
                            showSelectedValues={false}
                            taggingData={currentSearchResult.taggingData}
                        />
                    </Box>
                )}
                <SelectContainer>
                    <MakeModalNavigator
                        navigator={makeNavigator}
                        disabled={isSearchInProgress}
                        onSearch={triggerSearch}
                        searchId={currentSearchResult.searchId}
                        showSelectedValues={false}
                        taggingData={currentSearchResult.taggingData}
                    />

                    <ModalNavigator
                        navigator={modelNavigator}
                        onSearch={triggerSearch}
                        id="model"
                        label="Modell"
                        modalHeader="Alle Modelle"
                        showSelectedValues={false}
                        disabled={
                            isSearchInProgress || modelNavigator === undefined || modelNavigator.navigatorSelectionType === 'NOT_SELECTABLE'
                        }
                        taggingData={currentSearchResult.taggingData}
                    />

                    <Box display={{ phone: 'block', tablet: 'none' }}>
                        <ModalNavigator
                            navigator={typeNavigator}
                            disabled={isSearchInProgress}
                            onSearch={triggerSearch}
                            label="Fahrzeugtyp"
                            showSelectedValues={false}
                            taggingData={currentSearchResult.taggingData}
                        />
                    </Box>

                    <KeywordNavigator
                        navigator={keywordNavigator}
                        onSearch={triggerSearch}
                        disabled={isSearchInProgress}
                        abortRequest={abortRequest}
                        taggingData={currentSearchResult.taggingData}
                        showLabel={false}
                        placeholder="Suchbegriff"
                    />
                </SelectContainer>
                <Box display={{ phone: 'block', tablet: 'none' }} marginBottom={{ phone: 's', tablet: 'sm' }}>
                    <Text
                        fontSize={{ phone: 'm', tablet: 's' }}
                        fontWeight="bold"
                        color={searchBoxForegroundTextColor ?? 'palette.verydarkgrey'}
                    >
                        Basisdaten
                    </Text>
                </Box>
                <SelectContainer>
                    <RangeNavigatorComponent
                        navigator={mileageNavigator}
                        onSearch={triggerSearch}
                        abortRequest={abortRequest}
                        mode="to"
                        prependTo="km"
                        placeholderTo="km-Stand bis"
                        size={{ phone: 'large', tablet: 'medium' }}
                        disabled={false}
                        taggingData={currentSearchResult.taggingData}
                    />

                    <RangeNavigatorComponent
                        navigator={yearmodelNavigator}
                        onSearch={triggerSearch}
                        abortRequest={abortRequest}
                        mode="from"
                        prependFrom="EZ"
                        placeholderFrom="EZ ab"
                        size={{ phone: 'large', tablet: 'medium' }}
                        disabled={false}
                        taggingData={currentSearchResult.taggingData}
                        formatInput={false}
                    />

                    <RangeNavigatorComponent
                        navigator={priceNavigator}
                        onSearch={triggerSearch}
                        abortRequest={abortRequest}
                        mode="to"
                        prependTo="€"
                        placeholderTo="Preis bis"
                        size={{ phone: 'large', tablet: 'medium' }}
                        disabled={false}
                        taggingData={currentSearchResult.taggingData}
                    />
                </SelectContainer>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    flexDirection={{ phone: 'column', tablet: 'row' }}
                    alignItems={{ phone: 'flex-start', tablet: 'center' }}
                >
                    <Box flex="0 1 50%">
                        <MotorConditionCheckboxNavigator
                            type={type}
                            motorconditionNavigator={motorconditionNavigator}
                            triggerSearch={triggerSearch}
                            disabled={isSearchInProgress}
                            taggingData={currentSearchResult.taggingData}
                        />
                    </Box>
                    <Box flex="0 1 50%" display={{ phone: 'none', tablet: 'block' }}>
                        <Button
                            disabled={isSearchInProgress}
                            size="large"
                            width="100%"
                            testId="search-submit-button"
                            onClick={() => {
                                const url = resultListUrlForSearchResultOrType(currentSearchResult, type)
                                BbxRouter.push({
                                    href: url,
                                    clientSideRouting: true,
                                    beforeRouting: () =>
                                        callActionEvent('vertical_home_search_box_search_button_click', currentSearchResult.taggingData),
                                })
                            }}
                            css={css`
                                box-shadow: ${searchBoxSearchButtonOutlineActivated &&
                                `0 0 0 1px ${theme.colors.semantic.surface.DEFAULT}`};
                            `}
                        >
                            {formatNumber(currentSearchResult?.rowsFound) ?? ''} Fahrzeuge anzeigen
                        </Button>
                    </Box>
                </Box>

                <Box
                    flex="1 1 100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width={{ phone: '100%', tablet: 'auto' }}
                    marginTop={{ phone: 'sm', tablet: 'm' }}
                    paddingBottom={{ phone: 's', tablet: '0' }}
                    css={css`
                        > *:not(:last-child) {
                            margin-right: ${(p) => p.theme.space.m}px;
                        }
                    `}
                >
                    <Box display="flex" gap="s" alignItems="center">
                        <ClientRoutingAnchorLink
                            type="anchor"
                            href={detailSearchUrlForType(type, currentSearchResult)}
                            onClick={() => callActionEvent('vertical_home_search_box_detail_search_click', currentSearchResult.taggingData)}
                            testId="detailSearch"
                            css={css`
                                color: ${searchBoxForegroundLinkColor ?? theme.colors.palette.primary.main};
                                font-size: ${theme.fontSizes.s};
                                font-weight: ${theme.fontWeights.bold};
                            `}
                        >
                            Mehr Filter
                        </ClientRoutingAnchorLink>
                        {!!searchHistory?.searches.length && (
                            <Text
                                display={{ phone: 'none', tablet: 'block' }}
                                fontSize="s"
                                fontWeight="bold"
                                color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                            >
                                |
                            </Text>
                        )}
                        <SearchHistoryComponent
                            searchHistory={searchHistory}
                            header="Suchverlauf Auto-Motor"
                            display={{ phone: 'none', tablet: 'block' }}
                            color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                            taggingData={currentSearchResult.taggingData}
                            openActionSheetEvent="vertical_home_last_searches_click"
                            clickLastSearchEvent="vertical_home_last_searches_search_click"
                        />
                    </Box>
                    <ButtonWithLinkStyle
                        color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                        testId="resetSearch"
                        fontSize="s"
                        fontWeight="bold"
                        onClick={() => resetSearch()}
                        disabled={isSearchInProgress}
                    >
                        Filter zurücksetzen
                    </ButtonWithLinkStyle>
                </Box>
            </Box>

            <StickySearchButtonMobile
                searchResult={currentSearchResult}
                disabled={isSearchInProgress}
                clickEvent="vertical_home_search_box_search_button_click"
                searchBoxSearchButtonOutlineActivated={searchBoxSearchButtonOutlineActivated}
                backgroundColor={searchBoxBackgroundColor}
            >
                {formatNumber(currentSearchResult?.rowsFound) ?? ''} Fahrzeuge anzeigen
            </StickySearchButtonMobile>

            <Box justifyContent="center" paddingTop="s" display={{ phone: 'flex', tablet: 'none' }}>
                <SearchHistoryComponent
                    searchHistory={searchHistory}
                    header="Suchverlauf Auto-Motor"
                    color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                    taggingData={currentSearchResult.taggingData}
                    openActionSheetEvent="vertical_home_last_searches_click"
                    clickLastSearchEvent="vertical_home_last_searches_search_click"
                />
            </Box>
        </Box>
    )
}

const headingForType = (type: SearchBoxProps['type']): string => {
    switch (type) {
        case 'car':
            return 'Welches Fahrzeug suchst du?'
        case 'motorcycle':
            return 'Welches Motorrad suchst du?'
    }
}

const detailSearchUrlForType = (type: SearchBoxProps['type'], searchResult: SearchResult | undefined): string => {
    if (searchResult) {
        return detailSearchUrl(searchResult)
    }

    switch (type) {
        case 'car':
            return staticRelativeCanonicals.MotorCarDetailSearch
        case 'motorcycle':
            return staticRelativeCanonicals.MotorMcDetailSearch
    }
}

const resultListUrlForSearchResultOrType = (searchResult: SearchResult | undefined, type: SearchBoxProps['type']): string => {
    if (!searchResult) {
        switch (type) {
            case 'car':
                return staticRelativeCanonicals.MotorCarResultList
            case 'motorcycle':
                return staticRelativeCanonicals.MotorMcResultList
        }
    }

    return buildSeoUrl(findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.relativePath)
}

const SelectContainer: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => (
    <Box
        display="flex"
        flexDirection={{ phone: 'column', tablet: 'row' }}
        marginBottom={{ phone: 's', tablet: 'm' }}
        css={css`
            > * {
                min-width: 0;

                ${(p) => p.theme.media.tablet} {
                    flex: 1;
                    width: 33.33%;
                }
            }

            > *:not(:first-child) {
                margin-top: ${(p) => p.theme.space.s}px;

                ${(p) => p.theme.media.tablet} {
                    margin-top: 0;
                    margin-left: ${(p) => p.theme.space.s}px;
                }
            }
        `}
    >
        {children}
    </Box>
)
